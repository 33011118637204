import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode";

import axios from "axios";

export default function UpdateNew() {
  const { id: articleId } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newCategory, setNewCategory] = useState("");


  // Verify user role using useEffect
 useEffect(() => {
  const verifyUserRole = (requiredRoles) => {
    try {
      // Get the token from sessionStorage
      const token = sessionStorage.getItem("authToken");

      if (!token) {
        console.error("No token found. Redirecting to home.");
        navigate("/"); // Redirect to home
        return;
      }

      // Decode the token
      const decodedToken = jwtDecode(token);

      // Check if the token is expired
      const currentTime = Date.now() / 1000; // Current time in seconds
      if (decodedToken.exp < currentTime) {
        console.error("Token expired. Redirecting to home.");
        sessionStorage.removeItem("token"); // Remove expired token
        navigate("/"); // Redirect to home
        return;
      }

      // Check if the user's role matches the required roles
      if (!requiredRoles.includes(decodedToken.role)) {
        console.error("User role is not authorized. Redirecting to home.");
        navigate("/"); // Redirect to home
        return;
      }

      // If everything is valid, allow access
      console.log("User is authorized.");
    } catch (error) {
      console.error("Error verifying token:", error);
      navigate("/"); // Redirect to home in case of any error
    }
  };

  // Call verifyUserRole with required roles
  verifyUserRole(["admin", "moderator"]); // Replace with the roles you want to verify
}, [navigate]); // Add navigate to the dependency array


  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`https://new-b3dr.onrender.com/api/news/${articleId}`);
        const articleData = response.data;
        
        // Ensure each section has a tables array and proper structure
        const processedSections = articleData.sections.map(section => ({
          ...section,
          tables: (section.tables || []).map(table => ({
            ...table,
            cells: table.cells || Array(table.rows || 3).fill().map(() => Array(table.columns || 3).fill("")),
            rows: table.rows || 3,
            columns: table.columns || 3
          }))
        }));

        setArticle({
          ...articleData,
          sections: processedSections
        });
      } catch (err) {
        setError("Failed to fetch article: " + err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchArticle();
  }, [articleId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setArticle((prev) => ({ ...prev, [name]: value }));
  };

  const handleSectionChange = (index, field, value) => {
    const updatedSections = [...article.sections];
    updatedSections[index][field] = value;
    setArticle((prev) => ({ ...prev, sections: updatedSections }));
  };

  const handleFileUpload = (index, files) => {
    const updatedSections = [...article.sections];
    updatedSections[index].photos = Array.from(files).map((file) => file.name);
    setArticle((prev) => ({ ...prev, sections: updatedSections }));
  };

  const addCategory = () => {
    if (newCategory.trim()) {
      setArticle((prev) => ({
        ...prev,
        categories: [...(prev.categories || []), newCategory.trim()]
      }));
      setNewCategory("");
    }
  };

  const removeCategory = (indexToRemove) => {
    setArticle((prev) => ({
      ...prev,
      categories: prev.categories.filter((_, index) => index !== indexToRemove)
    }));
  };

  const handleTableChange = (sectionIndex, tableIndex, rowIndex, colIndex, value) => {
    setArticle(prevArticle => {
      const updatedSections = [...prevArticle.sections];
      if (!updatedSections[sectionIndex].tables[tableIndex].cells) {
        updatedSections[sectionIndex].tables[tableIndex].cells = 
          Array(updatedSections[sectionIndex].tables[tableIndex].rows)
            .fill()
            .map(() => Array(updatedSections[sectionIndex].tables[tableIndex].columns).fill(""));
      }
      updatedSections[sectionIndex].tables[tableIndex].cells[rowIndex][colIndex] = value;
      return { ...prevArticle, sections: updatedSections };
    });
  };

  const updateTableDimensions = (sectionIndex, tableIndex, newRows, newCols) => {
    setArticle(prevArticle => {
      const updatedSections = [...prevArticle.sections];
      const currentTable = updatedSections[sectionIndex].tables[tableIndex];
      
      // Create new cells array preserving existing data
      const newCells = Array(newRows).fill().map((_, rowIndex) =>
        Array(newCols).fill().map((_, colIndex) => {
          if (currentTable.cells && 
              rowIndex < currentTable.cells.length && 
              colIndex < currentTable.cells[0].length) {
            return currentTable.cells[rowIndex][colIndex];
          }
          return "";
        })
      );

      updatedSections[sectionIndex].tables[tableIndex] = {
        ...currentTable,
        rows: newRows,
        columns: newCols,
        cells: newCells
      };

      return { ...prevArticle, sections: updatedSections };
    });
  };

  const handleTableTitleChange = (sectionIndex, tableIndex, newTitle) => {
    setArticle(prevArticle => {
      const updatedSections = [...prevArticle.sections];
      updatedSections[sectionIndex].tables[tableIndex].title = newTitle;
      return { ...prevArticle, sections: updatedSections };
    });
  };

  const addTable = (sectionIndex) => {
    const newTable = {
      title: "New Table",
      rows: 3,
      columns: 3,
      cells: Array(3).fill().map(() => Array(3).fill(""))
    };
    
    setArticle(prevArticle => {
      const updatedSections = [...prevArticle.sections];
      if (!updatedSections[sectionIndex].tables) {
        updatedSections[sectionIndex].tables = [];
      }
      updatedSections[sectionIndex].tables.push(newTable);
      return { ...prevArticle, sections: updatedSections };
    });
  };

  const removeTable = (sectionIndex, tableIndex) => {
    setArticle(prevArticle => {
      const updatedSections = [...prevArticle.sections];
      updatedSections[sectionIndex].tables.splice(tableIndex, 1);
      return { ...prevArticle, sections: updatedSections };
    });
  };

  const addSection = () => {
    setArticle((prev) => ({
      ...prev,
      sections: [...prev.sections, { title: "", content: "", photos: [], tables: [] }]
    }));
  };

  const removeSection = (index) => {
    setArticle((prev) => ({
      ...prev,
      sections: prev.sections.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("mainTitle", article.mainTitle);
      formData.append("underTitle", article.underTitle);
      formData.append("categories", JSON.stringify(article.categories));
      formData.append("sections", JSON.stringify(article.sections));

      await axios.patch(`https://new-b3dr.onrender.com/api/news/${articleId}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      alert("Article updated successfully!");
      navigate("/admin/dashboard");
    } catch (err) {
      alert("Failed to update article: " + err.message);
    }
  };

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error) return <div className="text-center text-red-600 mt-10">{error}</div>;
  if (!article) return <div className="text-center mt-10">No article found</div>;

  return (
    <div className="max-w-4xl mx-auto p-8 bg-white shadow-2xl rounded-none mt-10 border-2 border-black">
      <h1 className="text-3xl font-bold mb-8 text-black border-b-4 border-red-600 pb-2 inline-block">
        Edit Article
      </h1>
      <form onSubmit={handleSubmit} className="space-y-8">
        {/* Main Title */}
        <div>
          <label className="block text-sm font-bold mb-2 text-black">Main Title</label>
          <input
            type="text"
            name="mainTitle"
            value={article.mainTitle}
            onChange={handleInputChange}
            className="w-full px-4 py-3 border-2 border-black rounded-none focus:outline-none focus:border-red-600 transition-colors"
            required
          />
        </div>

        {/* Under Title */}
        <div>
          <label className="block text-sm font-bold mb-2 text-black">Under Title</label>
          <input
            type="text"
            name="underTitle"
            value={article.underTitle}
            onChange={handleInputChange}
            className="w-full px-4 py-3 border-2 border-black rounded-none focus:outline-none focus:border-red-600 transition-colors"
            required
          />
        </div>

        {/* Categories */}
        <div>
          <label className="block text-sm font-bold mb-2 text-black">Categories</label>
          <div className="flex gap-2 mb-2">
            <input
              type="text"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              className="flex-1 px-4 py-3 border-2 border-black rounded-none focus:outline-none focus:border-red-600 transition-colors"
              placeholder="Add new category"
            />
            <button
              type="button"
              onClick={addCategory}
              className="px-4 py-2 bg-black text-white font-bold rounded-none hover:bg-red-600 transition-colors"
            >
              Add
            </button>
          </div>
          <div className="flex flex-wrap gap-2">
            {article.categories?.map((category, index) => (
              <span
                key={index}
                className="px-3 py-1 bg-black text-white rounded-none flex items-center gap-2"
              >
                {category}
                <button
                  type="button"
                  onClick={() => removeCategory(index)}
                  className="text-red-600 hover:text-red-400"
                >
                  ×
                </button>
              </span>
            ))}
          </div>
        </div>

        {/* Sections */}
        <div>
          <div className="flex justify-between items-center mb-4">
            <label className="block text-sm font-bold text-black">Sections</label>
            <button
              type="button"
              onClick={addSection}
              className="px-4 py-2 bg-black text-white font-bold rounded-none hover:bg-red-600 transition-colors"
            >
              Add Section
            </button>
          </div>

          {article.sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="border-2 border-black p-6 mb-6 hover:border-red-600 transition-colors">
              {/* Section Header */}
              <div className="flex justify-between items-center mb-4">
                <input
                  type="text"
                  placeholder="Section Title"
                  value={section.title || ""}
                  onChange={(e) => handleSectionChange(sectionIndex, "title", e.target.value)}
                  className="flex-1 px-4 py-3 border-2 border-black rounded-none focus:outline-none focus:border-red-600 transition-colors"
                />
                <button
                  type="button"
                  onClick={() => removeSection(sectionIndex)}
                  className="ml-2 px-4 py-2 bg-red-600 text-white font-bold rounded-none hover:bg-red-700 transition-colors"
                >
                  Remove Section
                </button>
              </div>

              {/* Section Content */}
              <textarea
                placeholder="Section Content"
                value={section.content || ""}
                onChange={(e) => handleSectionChange(sectionIndex, "content", e.target.value)}
                className="w-full px-4 py-3 border-2 border-black mb-4 rounded-none focus:outline-none focus:border-red-600 transition-colors"
                rows="4"
              ></textarea>

              {/* Photos */}
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2 text-black">Photos</label>
                <input
                  type="file"
                  multiple
                  onChange={(e) => handleFileUpload(sectionIndex, e.target.files)}
                  className="w-full px-4 py-3 border-2 border-black rounded-none focus:outline-none focus:border-red-600 transition-colors"
                />
                {section.photos && section.photos.length > 0 && (
                  <div className="flex flex-wrap gap-4 mt-4">
                    {section.photos.map((photo, photoIdx) => (
                      <div key={photoIdx} className="relative">
                        <img
                          src={photo}
                          alt={`Section ${sectionIndex + 1} Photo ${photoIdx + 1}`}
                          className="w-24 h-24 object-cover border-2 border-black"
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Tables */}
              <div className="mb-4">
                <div className="flex justify-between items-center mb-2">
                  <label className="block text-sm font-bold text-black">Tables</label>
                  <button
                    type="button"
                    onClick={() => addTable(sectionIndex)}
                    className="px-4 py-2 bg-black text-white font-bold rounded-none hover:bg-red-600 transition-colors"
                  >
                    Add Table
                  </button>
                </div>

                {section.tables?.map((table, tableIndex) => (
                  <div key={tableIndex} className="border-2 border-black p-4 mb-4">
                    <div className="flex justify-between items-center mb-2">
                      <input
                        type="text"
                        value={table.title || ""}
                        onChange={(e) => handleTableTitleChange(sectionIndex, tableIndex, e.target.value)}
                        className="px-4 py-2 border-2 border-black rounded-none focus:outline-none focus:border-red-600 transition-colors"
                        placeholder="Table Title"
                      />
                      <div className="flex gap-2">
                        <div className="flex items-center gap-2">
                          <label className="text-sm">Rows:</label>
                          <input
                            type="number"
                            min="1"
                            value={table.rows}
                            onChange={(e) => updateTableDimensions(sectionIndex, tableIndex, parseInt(e.target.value), table.columns)}
                            className="w-16 px-2 py-1 border-2 border-black rounded-none"
                          />
                        </div>
                        <div className="flex items-center gap-2">
                          <label className="text-sm">Columns:</label>
                          <input
                            type="number"
                            min="1"
                            value={table.columns}
                            onChange={(e) => updateTableDimensions(sectionIndex, tableIndex, table.rows, parseInt(e.target.value))}
                            className="w-16 px-2 py-1 border-2 border-black rounded-none"
                          />
                        </div>
                        <button
                          type="button"
                          onClick={() => removeTable(sectionIndex, tableIndex)}
                          className="px-4 py-2 bg-red-600 text-white font-bold rounded-none hover:bg-red-700 transition-colors"
                        >
                          Remove Table
                        </button>
                      </div>
                    </div>

                    <div className="overflow-x-auto">
                      <table className="w-full border-collapse border-2 border-black">
                        <tbody>
                          {table.cells.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              {row.map((cell, colIndex) => (
                                <td key={colIndex} className="border border-black p-1">
                                  <input
                                    type="text"
                                    value={cell}
                                    onChange={(e) => handleTableChange(sectionIndex, tableIndex, rowIndex, colIndex, e.target.value)}
                                    className="w-full px-2 py-1 focus:outline-none focus:bg-gray-100"
                                  />
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <button
          type="submit"
          className="px-8 py-3 bg-black text-white font-bold rounded-none hover:bg-red-600 transition-colors duration-300 border-2 border-black"
        >
          Save Changes
        </button>
      </form>
    </div>
  );
}