import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Facebook, Twitter, Instagram, MapPin, Phone, Mail } from 'lucide-react';

export default function Footer() {
  const [categories, setCategories] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get("https://new-b3dr.onrender.com/api/news/");
        const allCategories = response.data.flatMap((article) => article.categories);
        const uniqueCategories = [...new Set(allCategories)];
        setCategories(uniqueCategories);
      } catch (error) {
        console.error("Error fetching categories in Footer:", error);
      }
    };

    fetchCategories();
    setIsVisible(true);
  }, []);

  const handleCategoryClick = (category) => {
    navigate('/', { state: { selectedCategory: category } });
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer dir="rtl" className={`bg-gradient-to-bl from-emerald-50 via-green-50 to-yellow-50 text-gray-800 shadow-2xl border-t border-green-100 transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
      <div className="max-w-7xl mx-auto px-6 py-12 sm:px-8">
        {/* Top Section with Logo and Newsletter */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-12 gap-8">
          <div className="text-center md:text-right">
            <h2 className="text-3xl font-bold text-emerald-800 mb-2">موقع الأخبار</h2>
            <p className="text-gray-600">مصدرك الأول للأخبار الموثوقة</p>
          </div>
        </div>

        {/* Main Footer Content */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 mb-12">
          {/* About */}
          <div className="space-y-4">
            <h3 className="text-xl font-bold text-emerald-800 border-b-2 border-emerald-200 pb-2 mb-4">عن الموقع</h3>
            <p className="text-gray-600 leading-relaxed">
              نحن نسعى لتقديم محتوى إخباري عالي الجودة وموثوق به، مع التركيز على الدقة والمصداقية في نقل الأخبار.
            </p>
          </div>

          {/* Categories */}
          <div>
            <h3 className="text-xl font-bold text-emerald-800 border-b-2 border-emerald-200 pb-2 mb-4">الفئات</h3>
            <ul className="space-y-3">
              {categories.length > 0 ? (
                categories.map((category, index) => (
                  <li key={index}>
                    <button
                      onClick={() => handleCategoryClick(category)}
                      className="text-gray-600 hover:text-emerald-600 transition-colors duration-300 transform hover:-translate-x-2 inline-block"
                    >
                      {category}
                    </button>
                  </li>
                ))
              ) : (
                <li className="text-gray-500">جاري التحميل...</li>
              )}
            </ul>
          </div>

          {/* Social Links */}
          <div>
            <h3 className="text-xl font-bold text-emerald-800 border-b-2 border-emerald-200 pb-2 mb-4">تابعنا</h3>
            <div className="space-y-4">
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-3 text-gray-600 hover:text-blue-600 transition-colors duration-300 group"
              >
                <div className="w-10 h-10 rounded-full flex items-center justify-center group-hover:bg-blue-100 transition-colors duration-300">
                  <Facebook size={20} />
                </div>
                فيسبوك
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-3 text-gray-600 hover:text-sky-600 transition-colors duration-300 group"
              >
                <div className="w-10 h-10 rounded-full flex items-center justify-center group-hover:bg-sky-100 transition-colors duration-300">
                  <Twitter size={20} />
                </div>
                تويتر
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-3 text-gray-600 hover:text-pink-600 transition-colors duration-300 group"
              >
                <div className="w-10 h-10 rounded-full flex items-center justify-center group-hover:bg-pink-100 transition-colors duration-300">
                  <Instagram size={20} />
                </div>
                إنستغرام
              </a>
            </div>
          </div>

          {/* Contact */}
          <div>
            <h3 className="text-xl font-bold text-emerald-800 border-b-2 border-emerald-200 pb-2 mb-4">اتصل بنا</h3>
            <ul className="space-y-3">
              <li className="flex items-center gap-3 text-gray-600">
                <div className="w-10 h-10 rounded-full flex items-center justify-center">
                  <MapPin size={20} className="text-emerald-600" />
                </div>
                123 شارع الأخبار
              </li>
              <li className="flex items-center gap-3 text-gray-600">
                <div className="w-10 h-10 rounded-full flex items-center justify-center">
                  <Phone size={20} className="text-emerald-600" />
                </div>
                2229 :هاتف
              </li>
              <li className="flex items-center gap-3 text-gray-600">
                <div className="w-10 h-10 rounded-full flex items-center justify-center">
                  <Mail size={20} className="text-emerald-600" />
                </div>
                info@news.com
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-green-200 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center text-sm text-gray-600">
            <p className="mb-4 md:mb-0">
              &copy; {new Date().getFullYear()} موقع الأخبار. جميع الحقوق محفوظة
            </p>
            <div className="flex gap-6">
              <a
                href="#"
                className="hover:text-emerald-600 transition-colors duration-300"
              >
                سياسة الخصوصية
              </a>
              <a
                href="#"
                className="hover:text-emerald-600 transition-colors duration-300"
              >
                الشروط والأحكام
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}