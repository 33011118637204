import React, { useEffect, useState } from "react";
import axios from "axios";

export default function Bar() {
  const [alerts, setAlerts] = useState([]);
  const [currentAlertIndex, setCurrentAlertIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [slideDir, setSlideDir] = useState('entering'); // 'entering' or 'exiting'

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await axios.get("https://new-b3dr.onrender.com/api/alerts");
        const filteredAlerts = response.data.filter((alert) => alert.show);
        setAlerts(filteredAlerts);
        setError("");
      } catch (err) {
        setError("Failed to fetch alerts. Please try again later.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchAlerts();
  }, []);

  useEffect(() => {
    if (alerts.length === 0) return;

    const interval = setInterval(() => {
      setSlideDir('exiting');
      
      setTimeout(() => {
        setCurrentAlertIndex((prevIndex) => 
          prevIndex === alerts.length - 1 ? 0 : prevIndex + 1
        );
        setSlideDir('entering');
      }, 500); // Half of the total animation time
      
    }, 5000); // Change alert every 5 seconds

    return () => clearInterval(interval);
  }, [alerts]);

  if (loading) {
    return (
      <div className="fixed bottom-0 w-full bg-gray-100 py-2 flex justify-center items-center">
        <span className="text-gray-500 text-sm">Loading alerts...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="fixed bottom-0 w-full bg-red-100 py-2 flex justify-center items-center">
        <span className="text-red-600 text-sm">{error}</span>
      </div>
    );
  }

  if (alerts.length === 0) {
    return null;
  }

  const currentAlert = alerts[currentAlertIndex];

  return (
    <div className="fixed bottom-0 w-full bg-gray-100 py-4 overflow-hidden">
      <style>
        {`
          @keyframes slideInFromRight {
            0% {
              transform: translateX(100%);
              opacity: 0;
            }
            100% {
              transform: translateX(0);
              opacity: 1;
            }
          }

          @keyframes slideOutToLeft {
            0% {
              transform: translateX(0);
              opacity: 1;
            }
            100% {
              transform: translateX(-100%);
              opacity: 0;
            }
          }

          .alert-entering {
            animation: slideInFromRight 0.5s ease-out forwards;
          }

          .alert-exiting {
            animation: slideOutToLeft 0.5s ease-out forwards;
          }
        `}
      </style>
      <div className="flex justify-center items-center">
        <div 
          className={`alert-${slideDir}`}
          key={`${currentAlert._id}-${slideDir}`}
        >
          <span
            className={`inline-block text-lg font-medium ${
              currentAlert.alert ? "text-red-600 font-bold" : "text-gray-800"
            }`}
          >
            {currentAlert.alert ? "عاجل: " : ""} {currentAlert.new}
          </span>
        </div>
      </div>
    </div>
  );
}