import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./user/Home";
import CreateNew from "./admin/CreateNew";
import Dashboard from "./admin/Dashboard";
import Article from "./admin/Article";
import NewsFeed from "./user/Home";
import UpdateNew from "./admin/UpdateNew";
import PostAlert from "./admin/PostAlert";
import ShowAllAlert from "./admin/ShowAllAlert";
import EditAlert from "./admin/EditAlert";
import Login from "./admin/Login";
import Register from "./admin/Register";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<NewsFeed />} />
        <Route path="/admin/create-new" element={<CreateNew />} />
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/article/:id" element={<Article />} />
        <Route path="/admin/update-new/:id" element={<UpdateNew />} />
        <Route path="/admin/alert" element={<PostAlert />} />
        <Route path="/admin/allalert" element={<ShowAllAlert />} />
        <Route path="/admin/edit-alert/:id" element={<EditAlert />} />
        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/register" element={<Register />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
