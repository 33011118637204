import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {jwtDecode} from "jwt-decode";

import axios from 'axios';

const ShowAllAlert = () => {
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const navigate = useNavigate();

  // Verify user role using useEffect
 useEffect(() => {
  const verifyUserRole = (requiredRoles) => {
    try {
      // Get the token from sessionStorage
      const token = sessionStorage.getItem("authToken");

      if (!token) {
        console.error("No token found. Redirecting to home.");
        navigate("/"); // Redirect to home
        return;
      }

      // Decode the token
      const decodedToken = jwtDecode(token);

      // Check if the token is expired
      const currentTime = Date.now() / 1000; // Current time in seconds
      if (decodedToken.exp < currentTime) {
        console.error("Token expired. Redirecting to home.");
        sessionStorage.removeItem("token"); // Remove expired token
        navigate("/"); // Redirect to home
        return;
      }

      // Check if the user's role matches the required roles
      if (!requiredRoles.includes(decodedToken.role)) {
        console.error("User role is not authorized. Redirecting to home.");
        navigate("/"); // Redirect to home
        return;
      }

      // If everything is valid, allow access
      console.log("User is authorized.");
    } catch (error) {
      console.error("Error verifying token:", error);
      navigate("/"); // Redirect to home in case of any error
    }
  };

  // Call verifyUserRole with required roles
  verifyUserRole(["admin", "moderator"]); // Replace with the roles you want to verify
}, [navigate]); // Add navigate to the dependency array


  useEffect(() => {
    const fetchData = async () => {
      try {
        const alertsResponse = await axios.get('https://new-b3dr.onrender.com/api/alerts');
        setAlerts(alertsResponse.data);
        setError('');
      } catch (err) {
        setError('فشل في جلب التنبيهات. الرجاء المحاولة في وقت لاحق.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://new-b3dr.onrender.com/api/alerts/${id}`);
      setAlerts(alerts.filter((alert) => alert._id !== id));
      setMessage('تم حذف التنبيه بنجاح.');
    } catch (err) {
      setMessage('فشل في حذف التنبيه. الرجاء المحاولة مجددًا.');
      console.error(err);
    }
  };

  const Sidebar = () => (
    <div className={`fixed inset-y-0 left-0 z-30 w-64 bg-white shadow-lg transform ${
      isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
    } transition-transform duration-300 ease-in-out md:translate-x-0`}>
      <div className="p-6">
        <div className="mb-8 flex items-center justify-between">
          <h1 className="text-2xl font-bold text-green-600">NEWS ADMIN</h1>
          <button 
            onClick={() => setIsSidebarOpen(false)}
            className="md:hidden text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>

        <nav className="space-y-4">
          <Link
            to="/admin/dashboard"
            className="block py-2 text-gray-600 hover:text-green-600"
          >
            Dashboard
          </Link>
          <Link
            to="/admin/allalert"
            className="block py-2 text-green-600 font-semibold"
          >
            → Alert
          </Link>
        </nav>
      </div>
    </div>
  );

  const MainContent = () => (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-yellow-50 py-6 px-4">
      <div className="max-w-6xl mx-auto">
        <div className="bg-white rounded-2xl shadow-lg p-4 md:p-8">
          {/* Mobile Header with Menu Button */}
          <div className="md:hidden flex items-center justify-between mb-6">
            <button
              onClick={() => setIsSidebarOpen(true)}
              className="text-gray-600 hover:text-green-600"
            >
              ☰
            </button>
            <h1 className="text-xl font-bold text-green-600">NEWS ADMIN</h1>
            <div className="w-8"></div>
          </div>

          <div className="flex flex-col space-y-4 md:flex-row md:justify-between md:items-center mb-6">
            <h1 className="text-2xl md:text-3xl font-bold bg-gradient-to-r from-green-600 to-yellow-500 bg-clip-text text-transparent">
              جميع التنبيهات
            </h1>
            <div className="flex flex-col sm:flex-row gap-3">
              <button
                onClick={() => navigate('/admin/dashboard')}
                className="bg-gradient-to-r from-green-600 to-green-700 text-white py-2 px-4 rounded-lg text-sm md:text-base"
              >
                العودة
              </button>
              <button
                onClick={() => navigate('/admin/alert')}
                className="bg-gradient-to-r from-yellow-400 to-yellow-500 text-white py-2 px-4 rounded-lg text-sm md:text-base"
              >
                إضافة تنبيه
              </button>
            </div>
          </div>

          {message && (
            <div className={`p-3 rounded-lg mb-4 text-center text-sm md:text-base font-semibold ${
              message.includes('نجاح') 
                ? 'bg-green-100 text-green-700 border border-green-300' 
                : 'bg-red-100 text-red-700 border border-red-300'
            }`}>
              {message}
            </div>
          )}

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
            {alerts.map((alert) => (
              <div
                key={alert._id}
                className="bg-white rounded-xl shadow border border-green-100 overflow-hidden"
              >
                <div className="p-4">
                  <div className="bg-gradient-to-r from-green-50 to-yellow-50 rounded-lg p-3 mb-3">
                    <h2 className="text-lg font-bold text-gray-800 mb-2">{alert.new}</h2>
                    <div className="space-y-2 text-sm">
                      <p className="flex items-center justify-between text-gray-700">
                        <span className="font-medium">مهم:</span>
                        <span className={`px-2 py-1 rounded-full ${
                          alert.alert ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-700'
                        }`}>
                          {alert.alert ? 'نعم' : 'لا'}
                        </span>
                      </p>
                      <p className="flex items-center justify-between text-gray-700">
                        <span className="font-medium">مرئي:</span>
                        <span className={`px-2 py-1 rounded-full ${
                          alert.show ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-700'
                        }`}>
                          {alert.show ? 'نعم' : 'لا'}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <button
                      onClick={() => navigate(`/admin/edit-alert/${alert._id}`)}
                      className="flex-1 bg-gradient-to-r from-green-500 to-green-600 text-white py-2 px-3 rounded-lg text-sm"
                    >
                      تعديل
                    </button>
                    <button
                      onClick={() => handleDelete(alert._id)}
                      className="flex-1 bg-gradient-to-r from-red-500 to-red-600 text-white py-2 px-3 rounded-lg text-sm"
                    >
                      حذف
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  if (loading) return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-green-50 to-yellow-50">
      <div className="animate-pulse text-xl text-green-600 font-semibold">جارٍ تحميل التنبيهات...</div>
    </div>
  );
  
  if (error) return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-green-50 to-yellow-50">
      <div className="text-xl text-red-600 font-semibold">{error}</div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Overlay for mobile sidebar */}
      {isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-20 md:hidden"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}
      
      <Sidebar />
      
      <div className="md:ml-64">
        <MainContent />
      </div>
    </div>
  );
};

export default ShowAllAlert;