import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {jwtDecode} from "jwt-decode";

export default function Dashboard() {
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("all");
  const [sortBy, setSortBy] = useState("newest");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const navigate = useNavigate();

  // Verify user role using useEffect
 useEffect(() => {
  const verifyUserRole = (requiredRoles) => {
    try {
      // Get the token from sessionStorage
      const token = sessionStorage.getItem("authToken");

      if (!token) {
        console.error("No token found. Redirecting to home.");
        navigate("/"); // Redirect to home
        return;
      }

      // Decode the token
      const decodedToken = jwtDecode(token);

      // Check if the token is expired
      const currentTime = Date.now() / 1000; // Current time in seconds
      if (decodedToken.exp < currentTime) {
        console.error("Token expired. Redirecting to home.");
        sessionStorage.removeItem("token"); // Remove expired token
        navigate("/"); // Redirect to home
        return;
      }

      // Check if the user's role matches the required roles
      if (!requiredRoles.includes(decodedToken.role)) {
        console.error("User role is not authorized. Redirecting to home.");
        navigate("/"); // Redirect to home
        return;
      }

      // If everything is valid, allow access
      console.log("User is authorized.");
    } catch (error) {
      console.error("Error verifying token:", error);
      navigate("/"); // Redirect to home in case of any error
    }
  };

  // Call verifyUserRole with required roles
  verifyUserRole(["admin", "moderator"]); // Replace with the roles you want to verify
}, [navigate]); // Add navigate to the dependency array


  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get("https://new-b3dr.onrender.com/api/news/");
        setArticles(response.data);
        const allCategories = response.data.flatMap((article) => article.categories);
        const uniqueCategories = [...new Set(allCategories)];
        setCategories(uniqueCategories);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchArticles();
  }, []);

  const deleteArticle = async (id) => {
    if (!window.confirm("Are you sure you want to delete this article?")) return;

    try {
      await axios.delete(`https://new-b3dr.onrender.com/api/news/${id}`);
      setArticles((prevArticles) => prevArticles.filter((article) => article._id !== id));
    } catch (err) {
      alert(`Failed to delete article: ${err.message}`);
    }
  };

  const filteredArticles = articles
    .filter((article) =>
      article.mainTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      article.underTitle.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((article) =>
      filter === "all" ? true : article.categories.includes(filter)
    )
    .sort((a, b) => {
      if (sortBy === "newest") return new Date(b.createdAt) - new Date(a.createdAt);
      if (sortBy === "oldest") return new Date(a.createdAt) - new Date(b.createdAt);
      return 0;
    });

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Mobile Menu Button */}
      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className="fixed top-4 left-4 z-50 md:hidden bg-white p-2 rounded-lg shadow-lg"
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d={isSidebarOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
          />
        </svg>
      </button>

      {/* Sidebar */}
      <div className={`fixed left-0 top-0 h-full w-64 bg-white shadow-lg p-6 transition-transform duration-300 ease-in-out z-40 ${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      } md:translate-x-0`}>
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-green-600">NEWS ADMIN</h1>
        </div>

        <nav className="space-y-4">
          <a href="/admin/dashboard" className="flex items-center space-x-2 text-gray-600 hover:text-green-600">
            <span className="text-green-600">→</span>
            <span>Dashboard</span>
          </a>
          <a href="/admin/allalert" className="flex items-center space-x-2 text-gray-600 hover:text-green-600">
            <span>Alert</span>
          </a>

          <div className="mt-6">
            <h3 className="text-lg font-bold text-gray-700">Categories</h3>
            <div className="mt-4 space-y-2">
              <a
                href="#"
                onClick={() => {
                  setFilter("all");
                  setIsSidebarOpen(false);
                }}
                className={`block text-gray-600 hover:text-green-600 ${filter === "all" ? "font-semibold text-green-600" : ""}`}
              >
                All Categories
              </a>
              {categories.map((category, index) => (
                <a
                  key={index}
                  href="#"
                  onClick={() => {
                    setFilter(category);
                    setIsSidebarOpen(false);
                  }}
                  className={`block text-gray-600 hover:text-green-600 ${filter === category ? "font-semibold text-green-600" : ""}`}
                >
                  {category}
                </a>
              ))}
            </div>
          </div>
        </nav>
      </div>

      {/* Main Content */}
      <div className="md:ml-64">
        {/* Top Bar */}
        <div className="bg-white shadow-sm p-4 md:p-6">
          <div className="flex flex-col md:flex-row items-center justify-between gap-4">
            <div className="w-full md:flex-1 max-w-3xl">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search articles..."
                  className="w-full bg-gray-50 border border-gray-200 rounded-lg px-4 py-2 pl-10 focus:outline-none focus:border-green-600"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <svg
                  className="absolute left-3 top-2.5 h-5 w-5 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
            </div>
            <button
              className="w-full md:w-auto bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-lg transition-all"
              onClick={() => navigate("/admin/create-new")}
            >
              + New Article
            </button>
            <button
              className="w-full md:w-auto bg-yellow-600 hover:bg-green-700 text-white px-6 py-2 rounded-lg transition-all"
              onClick={() => navigate("/admin/register")}
            >
              + New mod
            </button>
          </div>
        </div>

        {/* Filters */}
        <div className="p-4 md:p-6 bg-white border-b border-gray-200">
          <div className="flex flex-col md:flex-row gap-4">
            <select
              className="w-full md:w-auto bg-gray-50 border border-gray-200 rounded-lg px-4 py-2"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="all">All Categories</option>
              {categories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>

            <select
              className="w-full md:w-auto bg-gray-50 border border-gray-200 rounded-lg px-4 py-2"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
            >
              <option value="newest">Newest First</option>
              <option value="oldest">Oldest First</option>
            </select>
          </div>
        </div>

        {/* Content */}
        <div className="p-4 md:p-6">
          {loading ? (
            <div className="flex justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-green-600"></div>
            </div>
          ) : error ? (
            <div className="text-red-500 text-center">{error}</div>
          ) : (
            <>
              <div className="mb-6">
                <h2 className="text-xl font-bold text-gray-800">{filteredArticles.length} Articles Found</h2>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-6">
                {filteredArticles.map((article) => (
                  <div
                    key={article._id}
                    className="bg-white rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 border border-gray-100 hover:border-green-600 group"
                  >
                    <div className="p-4 md:p-6">
                      <div className="flex flex-wrap items-center justify-between mb-4 gap-2">
                        <span className="text-xs text-gray-500">
                          {new Date(article.createdAt).toLocaleDateString()}
                        </span>
                        <span className="px-3 py-1 bg-yellow-100 text-yellow-800 text-xs rounded-full">
                          {article.categories.join(", ")}
                        </span>
                      </div>

                      <h2 className="text-lg md:text-xl font-bold mb-3 text-gray-800 group-hover:text-green-600 transition-colors">
                        {article.mainTitle}
                      </h2>

                      <p className="text-gray-600 mb-4 line-clamp-2">{article.underTitle}</p>

                      <div className="flex flex-wrap justify-between items-center gap-4">
                        {/* update button here 
                          <button
                          onClick={() => navigate(`/admin/update-new/${article._id}`)}
                          className="text-green-600 hover:text-green-700 font-medium"
                        >
                          Edit Article →
                        </button>
        
        */}

                        <div className="flex gap-3">
                          <button
                            onClick={() => deleteArticle(article._id)}
                            className="p-2 hover:bg-red-50 rounded-full text-red-500"
                          >
                            <svg
                              className="w-5 h-5"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 12H5"
                              />
                            </svg>
                          </button>

                          <button
                            onClick={() => navigate(`/admin/article/${article._id}`)}
                            className="p-2 hover:bg-green-50 rounded-full text-green-600"
                          >
                            <svg
                              className="w-5 h-5"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0zm-3-9a9 9 0 100 18 9 9 0 000-18z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}